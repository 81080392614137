.filter-section {
  padding: 1em;
  display: flex;
  justify-content: flex-start;
}
.filter-section .search-box,
.filter-section .search-box:hover,
.filter-section .search-box:focus,
.filter-section .search-box:active {
  /* border-radius: 2em; */
  /* border: 1px solid #dac9ff; */
  background-color: #fbf9ff;
  color: #331773;
  /* font-size: 0.8em; */
  /* padding: 1em; */
  outline: none;
  /* width: 289px; */
}

.filter-section .device-type,
.filter-section .device-type select,
.filter-section .device-type:hover,
.filter-section .device-type:focus,
.filter-section .device-type:active {
  border-radius: 5px;
  border: 1px solid #dac9ff;
  background: #fff;
  outline: none;
  font-family: "DM Sans", sans-serif;
}
/* .filter-section .device-type ul li {
  color: var(--theme-text-color);
  font-family: "DM Sans", sans-serif;
} */
.filter-section .btn {
  background: var(--theme-text-color);
  border-radius: 30px;
  border: none;
  /* padding: 0.5em 0; */
  justify-content: center;
}
.filter-section .btn label,
.filter-section .btn span,
.filter-section .btn button {
  color: white;
  border: none;
  text-transform: capitalize;
  font-size: 0.7em;
  font-family: "DM Sans", sans-serif;
}
/* .filter-section .date-mr {
  /* margin-right: 7em; */
/* }  */
.functionbuttons{
  display: flex;
  justify-content: flex-end;
}
.asset-list-table {
  border: 0;
  border-radius: 5px;
}
.asset-list-table .asset-list-table-header {
  background-color: var(--theme-text-color);
  border: 0;
  color: #fff;
  font-size: 18px;
  font-family: DM Sans;
  outline: none;
}
.asset-list-table--addToInventory-cell button,
.asset-list-table--addToInventory-cell button:hover,
.asset-list-table--addToInventory-cell button:active {
  text-decoration: underline;
  color: var(--theme-text-color);
  font-family: DM Sans;
  font-size: 16px;
  outline: none;
  border: 0;
  background: none;
}
.asset-list-table .asset-list-table--link-cell {
  color: #c301cf;
  text-decoration: underline;
  cursor: pointer;
}
/* .asset-list-table .asset-list-table--device-cell {
  padding-left: 2em;
} */
.modal-dialog {
  width: 770px;
}
/* .add-to-inventory-table table {
  border: 0;
} */
.modal-table table th {
  color: var(--theme-text-color);
  font-family: DM Sans;
  font-size: 16px;
  line-height: normal;
  padding: 0.5em 1em;
  /* width: 130px; */
}
.modal-table .modal-table--input {
  color: #dac9ff;
}

.modal-table .modal-table--input label,
.modal-table .modal-table--select {
  font-size: 16px;
  font-family: DM Sans;
  color: #757575;
}
.btn-modal button,
.btn-modal button:hover {
  margin: 0 auto;
  background-color: var(--theme-text-color);
  color: #fff;
  font-size: 16px;
  border-radius: 30px;
  text-transform: capitalize;
  width: 154px;
}

p.message {
  font-size: 0.8em;
  text-align: center;
  margin: 0;
  padding: 1em 0 0;
}
