.login-container {
  display: grid;
  grid-template-columns: auto 1fr;
}
.login-image {
  background-image: url(../../assets/images/login-image.png);
  background-repeat: no-repeat;
  width: 841px;
  height: 1024px;
}
.login-form {
  margin: 0 auto;
  padding-top: 15em;
  width: 100%;
}
.login-title {
  text-align: center;
}
.login-title h3 {
  font-size: 1.4em;
}
.login-container form {
  padding: 0 2em;
}
.form-group {
  display: flex;
  flex-direction: column;
}
.form-group label {
  margin-bottom: 0.5em;
}
.form-group input {
  border-radius: 0.8em;
  border: 1px solid #dac9ff;
  background: #fff;
  padding: 1.2em;
  font-size: 1.1em;
}
.form-group .email-input,
.form-group .password-input {
  background-repeat: no-repeat;
  background-position: 18.3em 0.8em;
}
.form-group .email-input {
  background-image: url(../../assets/images/mail.png);
}
.form-group .password-input {
  background-image: url(../../assets/images/lock.png);
}
.login-container p {
  text-align: right;
  font-size: 0.8em;
}
.login-container p button {
  text-decoration: underline;
  cursor: pointer;
  background: transparent;
  border: none;
  color: var(--theme-text-color);
}
form div button {
  border-radius: 2em;
  background: #331773;
  color: #fff;
  padding: 1.2em 5em 1.2em 3em;
  font-size: 1em;
  background-image: url(../../assets/images/arrow-right.png);
  background-repeat: no-repeat;
  background-position: 6.6em 0;
  cursor: pointer;
}
