.successMessage {
  margin-left: 1rem;
}
p.checked-out-confirm {
  font-size: 38px;
  font-weight: 700;
}
p.checkout-message {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 2rem;
}
p.form-label {
  font-size: 18px;
  font-weight: 600;
}
.NextButtonText {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}
.SubmitButtonText {
  color: #6a6a6a;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}
.SubmitButtonValid {
  background-color: #331773 !important;
}
.SubmitButton {
  background-color: #dfdfdf !important;
  cursor: not-allowed !important;
  box-shadow: none !important;
  outline: none !important;
}
p.formName {
  color: #331773;
  font-size: 24px;
  font-weight: 700;
  margin-top: 1rem;
  margin-bottom: 0rem;
}
.defaultField {
  border: 1px solid #dac9ff !important;
  color: #757575 !important;
}
.filledField {
  color: #331773 !important;
  border: 1px solid #dac9ff !important;
  background-color: #fbf9ff !important;
}
.MuiOutlinedInput-input {
  -webkit-text-fill-color: #331773 !important;
}
.MuiStepLabel-label {
  font-weight: 600 !important;
  font-size: 24px !important;
}
.MuiStepLabel-label.Mui-active {
  color: #331773 !important;
}
.MuiStepIcon-root.Mui-active {
  color: #331773 !important;
}
