.header-container {
  display: flex;
  justify-content: space-between;
  background-color: #5a3092;
  color: white;
  /* width: 100%; */
}
/* .header-container-left {
  /* display: flex; */
/* } */ 
h1.project-title {
  font-family: "DM Sans", sans-serif;
  font-size: 1.2em;
  font-weight: 600;
  /* margin-left: 4rem; */
  margin: 0;
  padding: 0.5em 0 0 2em;
  cursor: pointer;
}
.header-container-right {
  display: flex;
  align-items: center;
  padding-right: 2em;
}
p.username {
  /* font-family: Roboto; */
  font-size: 0.8em;
  font-weight: 700;
  padding: 0 1em 0 0.5em;
}
.asset-checkout-image {
  background-image: url(../../assets/images/AssetCheckoutImage.png);
  background-repeat: no-repeat;
  /* width: 1440px; */
  background-size: cover;
  border-radius: "3rem";
  height: 300px;
}
