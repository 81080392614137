:root {
  --theme-text-color: #331773;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

.bold {
  font-weight: bold;
}

.pb-1 {
  padding-bottom: 1em;
}
.pb-5 {
  padding-bottom: 5em;
}
.text-center {
  text-align: center;
}

body {
  margin: 0;
  padding: 0;
  background: #e7e7e7;
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--theme-text-color);
}
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
